import {ITransactionPriorityEnum} from "../../ConsolidationTool/types";
import {IWeb3DisperseFacade} from "./IWeb3DisperseFacade";
import {ETH_DisperseFacade} from "./ETH_DisperseFacade";
import {GasHelper} from "../../../helpers";
import {ITxDataSimple} from "../../../models/chainScan.models";
import {setProviderWeb3, toHex} from "../../../store/web3/web3";
import {IDataEstimateDisperseTransactions, IEstimateFeeForDisperseResultType} from "../types";

class OPBNB_DisperseFacade extends ETH_DisperseFacade implements IWeb3DisperseFacade {
  protected static _feeL1ForSingleTransaction: bigint = BigInt(0)
  protected static _feeL1ForMultipleTransaction: bigint = BigInt(0)

  constructor() {
    super({
      web3HttpProviderLink: process.env.REACT_APP_OPBNB_WEB3_HTTP_PROVIDER,
      network: "opbnb",
      linkForTxScan: process.env.REACT_APP_LINK_FOR_TX_OPBNB_SCAN,
      defaultTransactionPriority: "fast",
      fetchGasPriceConf: {
        apikey: process.env.REACT_APP_LINK_FOR_OPBNB_GAS_PRICE_API_KEY,
        url: process.env.REACT_APP_LINK_FOR_OPBNB_GAS_PRICE_API,
        devUrl: process.env.REACT_APP_LINK_FOR_OPBNB_SCAN_API
      },
      addressesChunkSize: 200
    })
  }

  async estimateTransactions(data: IDataEstimateDisperseTransactions): Promise<IEstimateFeeForDisperseResultType> {
    const estimatedFee = await super.estimateTransactions(data)

    const {amountInUnitByReceiver, senderAccount} = data
    const {value: toAddress} = amountInUnitByReceiver.keys().next()

    if (OPBNB_DisperseFacade._feeL1ForSingleTransaction === BigInt(0)) {
      const estimateFeeL1ForSingleTransactionData: ITxDataSimple = {
        value: toHex(amountInUnitByReceiver.get(toAddress)!),
        from: senderAccount.address,
        to: toAddress
      }
      OPBNB_DisperseFacade._feeL1ForSingleTransaction = await this._fetchFeeForL1(estimateFeeL1ForSingleTransactionData)
    }
    estimatedFee.notOptimizedFeeInUnit += OPBNB_DisperseFacade._feeL1ForSingleTransaction * BigInt(amountInUnitByReceiver.size)

    estimatedFee.optimizedFeeInUnit = BigInt(0)

    return estimatedFee
  }

  resetInfoForSendTransaction() {
    super.resetInfoForSendTransaction();
    OPBNB_DisperseFacade._feeL1ForSingleTransaction = BigInt(0)
    OPBNB_DisperseFacade._feeL1ForMultipleTransaction = BigInt(0)
  }

  async _fetchGasPriceInWei(transactionPriority: keyof ITransactionPriorityEnum): Promise<bigint> {
    const {getGasPriceInWei} = this._web3Provider
    const slowInWei: bigint = await getGasPriceInWei()
    switch (transactionPriority) {
      case 'slow':
        return GasHelper.gasPricePlusPercent(slowInWei, 10)
      case 'average':
        return GasHelper.gasPricePlusPercent(slowInWei, 50)
      case 'fast':
        return GasHelper.gasPricePlusPercent(slowInWei, 100)
      default:
        return BigInt(0)
    }
  }

  async _fetchFeeForL1(txObject: ITxDataSimple) {
    const l1Provider = setProviderWeb3(process.env.REACT_APP_BSC_WEB3_HTTP_PROVIDER);
    const l1GasPrice = await l1Provider.getGasPriceInWei();

    const txData = txObject.data || "0x";
    const zeroBytes = (txData.match(/00/g) || []).length;
    const nonZeroBytes = (txData.length / 2) - zeroBytes;
    const txDataGas = (zeroBytes * 4) + (nonZeroBytes * 16);

    const fixedOverhead = 2100;
    return BigInt(l1GasPrice) * BigInt(txDataGas + fixedOverhead)
  }
}

export {OPBNB_DisperseFacade}