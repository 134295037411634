import {ITokenDict, NetworkCurrencyEnum} from "../../pages/ConsolidationTool/types";
import bscEmpty from '../../assets/images/bsc-empty-token.svg'

const BEP20_DEFAULT_IMG = bscEmpty as string

const BEP20Tokens: ITokenDict = {
  [NetworkCurrencyEnum.bsc]: {
    "title": "Binance Coin",
    "img": "https://bscscan.com/assets/bsc/images/svg/logos/token-light.svg",
    "rate": "$405.00",
    "address": undefined,
    "link": "https://bscscan.com/",
    "group": `Base Currency (${NetworkCurrencyEnum.bsc})`,
    "website": "https://www.bnbchain.org/",
    "desc": "",
    "decimal": 18,
    "symbol": NetworkCurrencyEnum.bsc
  },
  'BUSD': {
    "title": "Binance-Peg BUSD Token",
    "img": "https://bscscan.com/token/images/busd_32_2.png",
    "rate": "$1.01",
    "address": "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    "link": "https://bscscan.com/token/0xe9e7cea3dedca5984780bafc599bd69add087d56",
    "group": "Tokens (BEP 20)",
    "website": "https://www.binance.com/en/busd",
    "desc": "",
    "decimal": 18,
    "symbol": "BUSD"
  },
  'USDC': {
    "title": "Binance-Peg USD Token",
    "img": "https://bscscan.com/token/images/centre-usdc_28.png",
    "rate": "$1.00",
    "address": "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    "link": "https://bscscan.com/token/0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    "group": "Tokens (BEP 20)",
    "website": "https://www.usdc.com/",
    "desc": "",
    "decimal": 18,
    "symbol": "USDC"
  },
  'SHIB': {
    "title": "Binance-Peg SHIBA INU Token",
    "img": "https://bscscan.com/token/images/shibatoken_32.png",
    "rate": "$0.00001471",
    "address": "0x2859e4544C4bB03966803b044A93563Bd2D0DD4D",
    "link": "https://bscscan.com/token/0x2859e4544C4bB03966803b044A93563Bd2D0DD4D",
    "group": "Tokens (BEP 20)",
    "website": "https://shibatoken.com/",
    "desc": "",
    "decimal": 18,
    "symbol": "SHIB"
  },
  'BSC-USD': {
    "title": "Binance-Peg BSC-USD Token",
    "img": "https://bscscan.com/token/images/busdt_32.png",
    "rate": "$1.00",
    "address": "0x55d398326f99059fF775485246999027B3197955",
    "link": "https://bscscan.com/token/0x55d398326f99059fF775485246999027B3197955",
    "group": "Tokens (BEP 20)",
    "website": "",
    "desc": "",
    "decimal": 18,
    "symbol": "BSC-USD"
  },
}

export {BEP20Tokens, BEP20_DEFAULT_IMG}