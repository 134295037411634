import {
  AddressType,
  IAccount,
  IMapValueByAddress,
  ITransactionPriorityEnum,
  NetworkType,
  PrivateKeyType
} from "../../ConsolidationTool/types";
import {IWeb3DisperseFacade} from "./IWeb3DisperseFacade";
import {
  fromWei,
  GAS_FOR_SEND_ETHEREUM,
  HexStr,
  isAddress,
  isHexStrict,
  setProviderWeb3,
  toHex,
  toWei,
  Web3InitiatedType
} from "../../../store/web3/web3";
import {
  AddressHexStr,
  IContractAbiFragment,
  IRpcResponse, ITxDataSimple,
} from "../../../models/chainScan.models";
import {GasHelper} from "../../../helpers";
import {Web3Account} from "web3-eth-accounts";
import {JsonRpcError} from "web3-types/src/json_rpc_types";
import {
  EthereumDisperseABI,
  EthereumDisperseContractAddress, MAX_TX_IN_ETH,
} from "../../../store/etherscan/ERC20DisperseABI";
import {
  IDataEstimateUnitsForMultiTransaction,
  IDataEstimateDisperseTransactions,
  IEstimateFeeForDisperseResultType, IDataEstimateUnitsForSingleTransaction, IDataSendDisperseTransactions
} from "../types";
import {MAX_TX_IN_TOKEN} from "../../../store/basescan/BASEDisperseABI";

export type InitERCDisperseDataType = {
  network: NetworkType,
  linkForTxScan: string,
  web3HttpProviderLink: string,
  fetchGasPriceConf: { apikey: string; url: string, devUrl?: string }
  defaultTransactionPriority: keyof ITransactionPriorityEnum,

  limitAddresses?: number,
  addressesChunkSize?: number,
}

const ETHInitDisperseData: InitERCDisperseDataType = {
  web3HttpProviderLink: process.env.REACT_APP_ETH_WEB3_HTTP_PROVIDER,
  fetchGasPriceConf: {
    apikey: process.env.REACT_APP_LINK_FOR_ETH_GAS_PRICE_API_KEY,
    url: process.env.REACT_APP_LINK_FOR_ETH_GAS_PRICE_API,
    devUrl: process.env.REACT_APP_LINK_FOR_ETH_SCAN_API
  },
  network: "eth",
  linkForTxScan: process.env.REACT_APP_LINK_FOR_TX_ETH_SCAN,
  defaultTransactionPriority: "high",
}

interface IRawGasPriceItem {
  acceptance: number,
  gasPrice: number | string,
  estimatedFee: number
}

interface IETHGasPrice {
  low: bigint,
  medium: bigint,
  high: bigint
}

function adapterGasPrice(rawResult: IRawGasPriceItem[]): IETHGasPrice {
  return {
    low: BigInt(rawResult[1].gasPrice),
    medium: BigInt(rawResult[2].gasPrice),
    high: BigInt(rawResult[3].gasPrice)
  }
}

class ETH_DisperseFacade implements IWeb3DisperseFacade {
  protected readonly _web3Provider: Web3InitiatedType
  protected readonly _fetchGasPriceConf: { apikey: string; url: string, devUrl?: string }
  protected readonly _network: NetworkType
  protected readonly _linkForTxScan: string
  protected readonly _defaultTransactionPriority: keyof ITransactionPriorityEnum
  protected readonly _disperseContractABI: IContractAbiFragment[]
  protected readonly _disperseContractAddress: AddressHexStr

  protected readonly limitAddresses: number;
  protected readonly addressesChunkSize: number;

  protected readonly _environment;

  protected static _chainId: bigint | 0 = 0
  protected static _networkId: bigint | 0 = 0
  protected static _nonce: bigint | 0 = 0
  private _disperse_limits: { native_coin: number; tokens: number };

  protected static readonly _ethProvider: Web3InitiatedType = setProviderWeb3(ETHInitDisperseData.web3HttpProviderLink)

  constructor(initData?: InitERCDisperseDataType) {
    this._web3Provider = setProviderWeb3(initData?.web3HttpProviderLink || ETHInitDisperseData.web3HttpProviderLink)
    this._fetchGasPriceConf = initData?.fetchGasPriceConf || ETHInitDisperseData.fetchGasPriceConf
    this._network = initData?.network || ETHInitDisperseData.network
    this._linkForTxScan = initData?.linkForTxScan || ETHInitDisperseData.linkForTxScan
    this._defaultTransactionPriority = initData?.defaultTransactionPriority || ETHInitDisperseData.defaultTransactionPriority
    this._disperseContractABI = EthereumDisperseABI
    this._disperseContractAddress = EthereumDisperseContractAddress
    this._disperse_limits = {
      native_coin: MAX_TX_IN_ETH,
      tokens: MAX_TX_IN_TOKEN,
    }

    this.limitAddresses = initData?.limitAddresses || 10000
    this.addressesChunkSize = initData?.addressesChunkSize || 700

    this._environment = process.env.REACT_APP_ENVIRONMENT
  }

  get linkForTxScan() {
    return this._linkForTxScan
  }

  get network() {
    return this._network
  }

  getLimitReceiver(): number {
    return this.limitAddresses
  }

  getChunkSize(): number {
    return this.addressesChunkSize
  }

  async __getGasLimit(sender: AddressType, receiver: AddressType): Promise<bigint> {
    return BigInt(GAS_FOR_SEND_ETHEREUM)
  }

  async estimateTransactions(data: IDataEstimateDisperseTransactions): Promise<IEstimateFeeForDisperseResultType> {
    const _gasPriceWei = await this._fetchGasPriceInWei(this._defaultTransactionPriority)

    //calculate fee without optimization
    const notOptimizedFeeInUnit: bigint = await this._estimateUnitsForSingleTransactions(data)

    // calculate fee with optimization
    const optimizedFeeInUnit: bigint = await this._estimateUnitsForMultiTransaction(data)

    return {
      notOptimizedFeeInUnit: GasHelper.gasPay(notOptimizedFeeInUnit * _gasPriceWei),
      optimizedFeeInUnit: GasHelper.gasPay(optimizedFeeInUnit * _gasPriceWei)
    }
  }

  async _estimateUnitsForSingleTransactions(data: IDataEstimateUnitsForSingleTransaction): Promise<bigint> {
    const gas = await this.__getGasLimit(data.senderAccount.address, data.amountInUnitByReceiver.keys().next().value)
    return Promise.resolve<bigint>(gas * BigInt(data.amountInUnitByReceiver.size))
  }

  async _estimateUnitsForMultiTransaction(data: IDataEstimateUnitsForMultiTransaction): Promise<bigint> {
    const {amountInUnitByReceiver, senderAccount, totalSendInUnit} = data
    if (amountInUnitByReceiver.size > this._disperse_limits.native_coin) {
      throw new Error(`Disperse max limit for native coin is ${this._disperse_limits.native_coin} receivers per tx`)
    }
    const {getDisperseContract, estimateGasForDisperse, BatchRequest} = this._web3Provider
    const {disperseEther} = getDisperseContract(this._disperseContractABI, this._disperseContractAddress)
    const batchInfo = new BatchRequest()

    const estimateGasData: ITxDataSimple = {
      from: senderAccount.address,
      to: this._disperseContractAddress,
      value: toHex(totalSendInUnit),
      data: disperseEther(
        [...amountInUnitByReceiver.keys()] as AddressHexStr[],
        [...amountInUnitByReceiver.values()] as bigint[]
      ).encodeABI(),
    }

    batchInfo.add(estimateGasForDisperse.request(estimateGasData))

    const dataBatch = await batchInfo.execute({timeout: 30000})
    const estimateGasResult = dataBatch[0]

    if (estimateGasResult.error) {
      const errorData = estimateGasResult.error as JsonRpcError
      throw new Error(`${errorData.message} [${errorData.code}]`)
    }
    const itemSuccess = estimateGasResult as IRpcResponse
    return BigInt(itemSuccess.result)
  }

  isExceptSender(receiverAddress: AddressHexStr, senderAddress: AddressHexStr): boolean {
    return receiverAddress.toLowerCase() === senderAddress?.toLowerCase()
  }

  async setInfoForSendTransaction(senderAccount: IAccount) {
    const {getTransactionCount, getChainId, getNetworkId} = this._web3Provider

    const countTransaction = await getTransactionCount(senderAccount.address)
    ETH_DisperseFacade._nonce = countTransaction || 0
    ETH_DisperseFacade._chainId = await getChainId()
    ETH_DisperseFacade._networkId = await getNetworkId()
  }

  resetInfoForSendTransaction() {
    ETH_DisperseFacade._chainId = 0
    ETH_DisperseFacade._networkId = 0
    ETH_DisperseFacade._nonce = 0
  }

  async sendTransactions(data: IDataSendDisperseTransactions): Promise<IMapValueByAddress> {
    const {isOptimizedFee} = data

    if (isOptimizedFee) {
      return this._sendMultiTransaction({
        amountInUnitByReceiver: data.amountInUnitByReceiver,
        senderAccount: data.senderAccount as Web3Account,
        totalSendInUnit: data.totalSendInUnit,
      })
    } else {
      return this._sendSingleTransactions({
        amountInUnitByReceiver: data.amountInUnitByReceiver,
        senderAccount: data.senderAccount as Web3Account
      })
    }
  }

  async _sendSingleTransactions(data: {
    amountInUnitByReceiver: IMapValueByAddress<bigint>
    senderAccount: Web3Account,
  }): Promise<IMapValueByAddress<HexStr>> {
    const {amountInUnitByReceiver, senderAccount} = data
    const {BatchRequest, sendSignedTransaction} = this._web3Provider

    const resultTxReceipt: IMapValueByAddress<HexStr> = new Map()
    const signedTxByAddress = new BatchRequest()

    const _gasPriceWei = await this._fetchGasPriceInWei(this._defaultTransactionPriority)
    const gas = await this.__getGasLimit(senderAccount.address, amountInUnitByReceiver.keys().next().value)

    for (const [account, amountInWei] of Array.from(amountInUnitByReceiver)) {
      if (this.isExceptSender(account as AddressHexStr, senderAccount.address as AddressHexStr)) continue

      // Sign transaction
      const {rawTransaction} = await senderAccount.signTransaction({
        gas: toHex(gas),
        from: senderAccount.address,
        to: account,
        value: toHex(amountInWei),
        gasPrice: toHex(_gasPriceWei),
        chainId: toHex(ETH_DisperseFacade._chainId),
        networkId: toHex(ETH_DisperseFacade._networkId),
        nonce: toHex(ETH_DisperseFacade._nonce++),
      })
      signedTxByAddress.add(sendSignedTransaction.request(rawTransaction, account as AddressHexStr))
    }

    //Send raw transaction by account
    const dataBatchTx = await signedTxByAddress.execute({timeout: 30000})
    for (let txResult of dataBatchTx) {
      if (txResult.error) {
        const errorData = txResult.error as JsonRpcError
        throw new Error(`${errorData.message} [${errorData.code}]`)
      }
      let itemSuccess = txResult as IRpcResponse
      resultTxReceipt.set(itemSuccess.id, itemSuccess.result as HexStr)
    }

    return resultTxReceipt
  }

  async _sendMultiTransaction(data: {
    amountInUnitByReceiver: IMapValueByAddress<bigint>;
    senderAccount: Web3Account;
    totalSendInUnit: bigint;
  }): Promise<IMapValueByAddress<HexStr>> {
    const {amountInUnitByReceiver, senderAccount, totalSendInUnit} = data
    const {getDisperseContract, sendSignedTransaction, BatchRequest} = this._web3Provider
    const signedTx = new BatchRequest()
    const {disperseEther} = getDisperseContract(this._disperseContractABI, this._disperseContractAddress)

    const _gasPriceWei = await this._fetchGasPriceInWei(this._defaultTransactionPriority)

    const gas = await this._estimateUnitsForMultiTransaction({
      amountInUnitByReceiver,
      senderAccount,
      totalSendInUnit
    })

    const {rawTransaction} = await senderAccount.signTransaction({
      gas: toHex(gas),
      from: senderAccount.address,
      to: this._disperseContractAddress,
      value: toHex(totalSendInUnit),
      gasPrice: toHex(_gasPriceWei),
      chainId: toHex(ETH_DisperseFacade._chainId),
      networkId: toHex(ETH_DisperseFacade._networkId),
      nonce: toHex(ETH_DisperseFacade._nonce++),
      data: disperseEther(
        [...amountInUnitByReceiver.keys()] as AddressHexStr[],
        [...amountInUnitByReceiver.values()] as bigint[]
      ).encodeABI()
    })

    signedTx.add(sendSignedTransaction.request(rawTransaction, senderAccount.address as AddressHexStr))
    const dataBatchTx = await signedTx.execute({timeout: 30000})
    const txResult = dataBatchTx[0]

    if (txResult.error) {
      const errorData = txResult.error as JsonRpcError
      throw new Error(`${errorData.message} [${errorData.code}]`)
    }
    const itemSuccess = txResult as IRpcResponse
    const resultTxReceipt: IMapValueByAddress<HexStr> = new Map()
    amountInUnitByReceiver.forEach((value, address) => resultTxReceipt.set(address, itemSuccess.result))

    return resultTxReceipt
  }

  async fetchBalanceInUnit(address: AddressType): Promise<bigint> {
    return this._web3Provider.getBalance(address)
  }

  validateAddress(address: AddressType): boolean {
    return isHexStrict(address) && isAddress(address)
  }

  privateKeyToAccount(privateKey: PrivateKeyType): IAccount {
    if (!isHexStrict(privateKey)) {
      privateKey = `0x${privateKey}`
    }

    return this._web3Provider.privateKeyToAccount(privateKey)
  }

  toUnitFromBaseCurrency(amount: string): bigint {
    return BigInt(toWei(amount, 'ether'))
  }

  toBaseCurrencyFromUnit(amount: bigint): string {
    return fromWei(amount, 'ether')
  }

  protected async _fetchGasPriceInWei(transactionPriority: keyof ITransactionPriorityEnum): Promise<bigint> {
    const response = await fetch(`${this._fetchGasPriceConf.url}?apikey=${this._fetchGasPriceConf.apikey}&eip1559=false&reportwei=true`, {
      method: "GET",
      headers: {"Content-Type": "application/json"},
    });
    const result = await response.json() as {
      timestamp: string,
      lastBlock: number,
      avgTime: number,
      avgTx: number,
      avgGas: number,
      avgL1Fee: number,
      speeds: IRawGasPriceItem[]
    };
    /**
     * In test(dev) env use blockscout rpc to get actual price for testnet
     */
    let gasPriceResult: IETHGasPrice = adapterGasPrice(result.speeds)
    if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
      const response = await fetch(`${this._fetchGasPriceConf.devUrl}/eth-rpc`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "id": 0,
          "method": "eth_gasPrice",
          "jsonrpc": "2.0",
          "params": []
        })
      })
      const result = await response.json() as {
        "jsonrpc": string,
        "result": HexStr,
        "id": number
      }
      const slowInWei: bigint = BigInt(result.result)

      switch (transactionPriority) {
        case 'low':
          return  GasHelper.gasPricePlusPercent(slowInWei, 10)
        case 'medium':
          return  GasHelper.gasPricePlusPercent(slowInWei, 50)
        case 'high':
          return GasHelper.gasPricePlusPercent(slowInWei, 100)
      }
    }

    return gasPriceResult[transactionPriority as keyof IETHGasPrice] || BigInt(0);
  }
}

export {ETH_DisperseFacade}