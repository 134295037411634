import {ETHFacade, InitDataType, ITxEthData} from "./ETHFacade";
import {
  AddressType, BalanceDataByAddress,
  EstimateResultType,
  IAccount,
  IDataForGenerateTransactions,
  IDataForSendTransactions,
  IMapValueByAddress,
  ITokenInfo,
  ITransactionPriorityEnum,
} from "../../types";
import {HexStr} from "../../../../store/web3/web3";
import {
  AddressHexStr,
  ApiScanResponse,
  IContractAbiFragment,
  IRpcResponse,
  ITxTokenBeforeEstimateGas
} from "../../../../models/chainScan.models";
import {JsonRpcError} from "web3-types/src/json_rpc_types";
import ERC20TokenABI from "../../../../store/etherscan/ERC20TokenABI";
import {GasHelper} from "../../../../helpers";
import {IWeb3TokenFacade} from "../IWeb3TokenFacade";
import {ERC20_DEFAULT_IMG} from "../../../../store/etherscan/ERC20Tokens";


export interface IDataForGenerateERC20Transactions extends IDataForGenerateTransactions {
  baseCurrencyBalanceData: BalanceDataByAddress,
  tokenBalanceData: BalanceDataByAddress,
  transactionPriority: keyof ITransactionPriorityEnum,
  receiverAddress: HexStr
}

export interface ITxErc20Data extends ITxEthData {
  data: HexStr
}

export interface IDataForSendERC20Transactions extends IDataForSendTransactions {
  baseCurrencyBalanceData: BalanceDataByAddress,
  tokenBalanceData: BalanceDataByAddress,
  privateKeyByAddress: IMapValueByAddress<IAccount['privateKey']>,
  transactionDataByAddress: IMapValueByAddress<ITxErc20Data>,
  transactionPriority: keyof ITransactionPriorityEnum,
  receiverAddress: HexStr
}

class ERC20Facade extends ETHFacade implements IWeb3TokenFacade {
  protected readonly _fetchTokenConf: { apikey: string; url: string };
  protected readonly limitPrivateKeys: number;
  protected readonly addressesChunkSize: number;
  private readonly _abi: IContractAbiFragment[];
  private readonly _defaultTokenImage: string;

  constructor(initData?: {
    baseInitData: InitDataType,
    tokenInitData: {
      abi: IContractAbiFragment[],
      fetchTokenConf: { apikey: string; url: string },
      defaultTokenImage?: string
    },
    limitPrivateKeys?: number,
    addressesChunkSize?: number
  }) {
    super(initData?.baseInitData);

    this._defaultTokenImage = initData?.tokenInitData?.defaultTokenImage || ERC20_DEFAULT_IMG
    this._abi = initData?.tokenInitData?.abi || ERC20TokenABI
    this._fetchTokenConf = initData?.tokenInitData?.fetchTokenConf || {
      apikey: process.env.REACT_APP_PRIVATE_KEY_FOR_ETH_SCAN_API,
      url: process.env.REACT_APP_LINK_FOR_ETH_SCAN_API
    }

    this.limitPrivateKeys = initData?.limitPrivateKeys || 15000;
    this.addressesChunkSize = initData?.addressesChunkSize || 800;
  }

  get defaultTokenImage(): string {
    return this._defaultTokenImage
  }

  getLimitPrivateKeys(): number {
    return this.limitPrivateKeys
  }

  getAddressesChunkSize(): number {
    return this.addressesChunkSize
  }

  async fetchBaseCurrencyBalanceDataByAddress(addressList:Set<AddressType>): Promise<BalanceDataByAddress> {
    return await super.fetchBaseCurrencyBalanceDataByAddress(addressList);
  }

  async fetchTokenBalanceDataByAddress(addressList: Set<AddressType>, tokenAddress: string): Promise<BalanceDataByAddress> {
    const balanceTokenByAddress: BalanceDataByAddress = new Map();

    if (tokenAddress) {
      if (addressList.size > this.getAddressesChunkSize()) {
        throw new Error(`Address size should be lower than ${this.getAddressesChunkSize()}`);
      }

      const {
        BatchRequest,
        getTokenContract,
      } = this._web3Provider

      const {balanceOf} = getTokenContract(this._abi, tokenAddress!);

      const batchInfo = new BatchRequest();
      addressList.forEach(address => {
        batchInfo.add(balanceOf.request(address as AddressHexStr))
      })
      const dataInfo = await batchInfo.execute({timeout: 30000})

      for (let item of dataInfo) {
        if (item.error) {
          const errorData = item.error as JsonRpcError
          throw new Error(errorData.message + `[${errorData.code}]`)
        }
        let itemSuccess = item as IRpcResponse

        balanceTokenByAddress.set(itemSuccess.id, balanceOf.outputFormatter(itemSuccess.result))
      }
    }
    return balanceTokenByAddress
  }

  async generateTransactions(data: IDataForGenerateERC20Transactions, tokenAddress?: AddressType): Promise<EstimateResultType> {
    if (!tokenAddress) return super.generateTransactions(data)

    const {tokenBalanceData, transactionPriority, receiverAddress} = data
    const {getTokenContract} = this._web3Provider

    const txDataForEstimateByAddress: IMapValueByAddress<ITxErc20Data> = new Map()

    const gasPriceInWei = await this._fetchGasPriceInWei(transactionPriority)
    const nonceByAddress = await this.__fetchNonce(new Set(tokenBalanceData.keys()))
    const chainId = await this._web3Provider.getChainId()
    const networkId = await this._web3Provider.getNetworkId()

    const {transfer} = getTokenContract(this._abi, tokenAddress!);

    tokenBalanceData.forEach((balanceToken, address) => {
      const accountHex: AddressHexStr = address as AddressHexStr
      if (accountHex.toLowerCase() === receiverAddress?.toLowerCase()) {
        return
      }

      if (balanceToken > 0) {
        const currentNonce = nonceByAddress.get(address)!
        ETHFacade.nonceByAddress.set(address, currentNonce + 1)

        txDataForEstimateByAddress.set(accountHex, {
          from: accountHex,
          to: tokenAddress,
          chainId: chainId,
          networkId: networkId,
          gasPrice: gasPriceInWei,
          value: BigInt(0),
          gas: BigInt(0),
          nonce: BigInt(currentNonce),
          data: transfer(receiverAddress as AddressHexStr, balanceToken.toString()).encodeABI()
        })
      }
    })

    return await this._estimateFee(txDataForEstimateByAddress, gasPriceInWei)
  }

  async sendTransactions(data: IDataForSendERC20Transactions, tokenAddress?: AddressType): Promise<IMapValueByAddress> {
    return super.sendTransactions(data)
  }

  async fetchTokenInfo(tokenAddress: string): Promise<ITokenInfo> {
    const params = {
      module: 'token',
      action: 'getToken',//TODO ONLY FOR blockscout.com/api/
      contractaddress: tokenAddress,
      apikey: this._fetchTokenConf.apikey
    }
    const data: ApiScanResponse<{
      cataloged: boolean;
      contractAddress: string;
      decimals: string;
      name: string;
      symbol: string;
      totalSupply: string;
      type: string;
    }> = await fetch(
      this._fetchTokenConf.url + '/?' + new URLSearchParams(params),
      {cache: "force-cache"}
    ).then(response => response.json())

    if (parseInt(data?.status || '0') === 0 && !data.result) {
      throw new Error(data.message)
    }

    if (parseInt(data?.status || '0') === 1 && Object.values(data.result).length) {
      const dataToken = data.result

      return {
        symbol: dataToken.symbol,
        title: dataToken.name,
        address: dataToken.contractAddress,
        decimal: Number(dataToken.decimals),
        img: this.tokensDict[dataToken?.symbol]?.img || this.defaultTokenImage
      };
    }
    throw new Error("Something was wrong...");
  }

  protected async _estimateFee(txDataForEstimateByAddress: IMapValueByAddress<ITxErc20Data>, gasPriceInWei: bigint) {
    const feeDataByAddress: IMapValueByAddress<bigint> = new Map()
    const txDataByAddress: IMapValueByAddress<ITxErc20Data> = new Map()

    console.log('erc20')
    const {BatchRequest, estimateGas} = this._web3Provider


    const batchGasEstimate = new BatchRequest();
    txDataForEstimateByAddress.forEach((txData) => {
      batchGasEstimate.add(estimateGas.request(this._toHexTxData(txData) as ITxTokenBeforeEstimateGas))
    })

    const gasArr = await batchGasEstimate.execute({timeout: 30000})
    for (let item of gasArr) {
      if (item.error) {
        const errorData = item.error as JsonRpcError
        throw new Error(errorData.message + `[${errorData.code}]`)
      }
      let itemSuccess = item as IRpcResponse
      const originGas = estimateGas.outputFormatter(itemSuccess.result)
      const gas = GasHelper.gasPay(originGas)

      txDataByAddress.set(itemSuccess.id, {
        ...txDataForEstimateByAddress.get(itemSuccess.id)!,
        gas: gas,
      })
      feeDataByAddress.set(itemSuccess.id, gasPriceInWei * gas)
    }

    return {txDataByAddress, feeDataByAddress}
  }
}

export {ERC20Facade};