import {IWeb3Facade} from "../IWeb3Facade";
import {
  IMapValueByAddress,
  ITransactionPriorityEnum
} from "../../types";
import {ETHFacade, InitDataType, ITxEthData} from "../ETH_Network/ETHFacade";
import {GasHelper} from "../../../../helpers";
import {OPBNBTestnetTokens} from "../../../../store/opbnbscan/OPBNBTestnetTokens";
import {OPBNBTokens} from "../../../../store/opbnbscan/OPBNBTokens";
import {setProviderWeb3} from "../../../../store/web3/web3";

export const OPBNBTransactionPriorityEnum: ITransactionPriorityEnum = {
  slow: "slow",
  average: "average",
  fast: "fast"
} as const

export const OPBNBInitData: InitDataType = {
  defaultTransactionPriority: OPBNBTransactionPriorityEnum.average,
  transactionPriorityOptions: {
    [OPBNBTransactionPriorityEnum.slow]: "Slow",
    [OPBNBTransactionPriorityEnum.average]: "Average",
    [OPBNBTransactionPriorityEnum.fast]: "Fast",
  },
  fetchGasPriceConf: {
    apikey: process.env.REACT_APP_LINK_FOR_OPBNB_GAS_PRICE_API_KEY,
    url: process.env.REACT_APP_LINK_FOR_OPBNB_GAS_PRICE_API,
    devUrl: process.env.REACT_APP_LINK_FOR_OPBNB_SCAN_API
  },
  web3HttpProviderLink: process.env.REACT_APP_OPBNB_WEB3_HTTP_PROVIDER,
  tokensDict: process.env.REACT_APP_ENVIRONMENT === 'dev' ? OPBNBTestnetTokens : OPBNBTokens,
  network: 'opbnb',
  linkForTxScan: process.env.REACT_APP_LINK_FOR_TX_OPBNB_SCAN,
  addressesChunkSize: 500
}

class OPBNBFacade extends ETHFacade implements IWeb3Facade {
  constructor(initData?: InitDataType) {
    super(initData || OPBNBInitData)
  }

  getTimeout(): number {
    return 500;
  }

  async _fetchGasPriceInWei(transactionPriority: keyof ITransactionPriorityEnum): Promise<bigint> {
    const {getGasPriceInWei} = this._web3Provider
    const slowInWei: bigint = await getGasPriceInWei()
    switch (transactionPriority) {
      case 'slow':
        return GasHelper.gasPricePlusPercent(slowInWei, 10)
      case 'average':
        return GasHelper.gasPricePlusPercent(slowInWei, 50)
      case 'fast':
        return GasHelper.gasPricePlusPercent(slowInWei, 100)
      default:
        return BigInt(0)
    }
  }

  protected async _estimateFee(txDataForEstimateByAddress: IMapValueByAddress<ITxEthData>, gasPriceInWei: bigint) {
    const {txDataByAddress, feeDataByAddress} = await super._estimateFee(txDataForEstimateByAddress, gasPriceInWei)

    /**
     * https://docs.bnbchain.org/bnb-opbnb/faq/gas-and-fees-faqs/#what-is-the-block-gas-limit-on-opbnb
     * l1_data_fee = l1_gas_price * (tx_data_gas + fixed_overhead) * dynamic_overhead
     * fixed_overhead = 2100
     * dynamic_overhead = 1
     * tx_data_gas = count_zero_bytes(tx_data) * 4 + count_non_zero_bytes(tx_data) * 16
    **/

    const l1Provider = setProviderWeb3(process.env.REACT_APP_BSC_WEB3_HTTP_PROVIDER);
    const l1GasPrice = await l1Provider.getGasPriceInWei();

    const txData = "0x";
    const zeroBytes = (txData.match(/00/g) || []).length;
    const nonZeroBytes = (txData.length / 2) - zeroBytes;
    const txDataGas = (zeroBytes * 4) + (nonZeroBytes * 16);

    const fixedOverhead = 2100;
    const l1Fee = BigInt(l1GasPrice) * BigInt(txDataGas + fixedOverhead);

    txDataForEstimateByAddress.forEach((_, address) => {
      const currentFee = feeDataByAddress.get(address)!
      feeDataByAddress.set(address, currentFee + l1Fee)
    })

    return {txDataByAddress, feeDataByAddress}
  }
}

export {OPBNBFacade}