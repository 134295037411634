import {
  AddressType,
  EstimateResultType,
  IMapValueByAddress,
  ITokenInfo,
  ITransactionPriorityEnum
} from "../../types";
import {IWeb3TokenFacade} from "../IWeb3TokenFacade";
import {
  ERC20Facade,
  IDataForGenerateERC20Transactions,
  IDataForSendERC20Transactions,
  ITxErc20Data
} from "../ETH_Network/ERC20Facade";
import ERC20TokenABI from "../../../../store/etherscan/ERC20TokenABI";
import {ApiScanResponse, ITransferEvents} from "../../../../models/chainScan.models";
/**
 * Solution for L2 chain
 */
import {setProviderWeb3} from "../../../../store/web3/web3";
import {OPBNBFacade, OPBNBInitData} from "./OPBNBFacade";
import {OPBNB_DEFAULT_IMG} from "../../../../store/opbnbscan/OPBNBTokens";

class OPBNB_ERC20Facade extends ERC20Facade implements IWeb3TokenFacade {
  protected static _feeL1: bigint = BigInt(0)

  constructor() {
    super({
      baseInitData: OPBNBInitData,
      tokenInitData: {
        defaultTokenImage: OPBNB_DEFAULT_IMG,
        abi: ERC20TokenABI,
        fetchTokenConf: {
          apikey: process.env.REACT_APP_PRIVATE_KEY_FOR_OPBNB_SCAN_API,
          url: process.env.REACT_APP_LINK_FOR_OPBNB_SCAN_API
        }
      },
      addressesChunkSize: 500
    });
  }

  getTimeout(): number {
    return 500;
  }

  async _fetchGasPriceInWei(transactionPriority: keyof ITransactionPriorityEnum): Promise<bigint> {
    const parentFacade = new OPBNBFacade(OPBNBInitData)
    return parentFacade._fetchGasPriceInWei(transactionPriority)
  }

  async generateTransactions(data: IDataForGenerateERC20Transactions, tokenAddress?: AddressType): Promise<EstimateResultType> {
    if (!tokenAddress) {
      const parentFacade = new OPBNBFacade(OPBNBInitData)
      return await parentFacade.generateTransactions(data)
    }
    return await super.generateTransactions(data, tokenAddress)
  }

  async sendTransactions(data: IDataForSendERC20Transactions, tokenAddress?: AddressType): Promise<IMapValueByAddress> {
    if (!tokenAddress) {
      const parentFacade = new OPBNBFacade(OPBNBInitData)
      return await parentFacade.sendTransactions(data)
    }

    return super.sendTransactions(data)
  }

  async fetchTokenInfo(tokenAddress: string): Promise<ITokenInfo> {
    const params = {
      module: 'account',
      action: 'tokentx',
      contractaddress: tokenAddress,
      page: '1',
      offset: '1',
      apikey: this._fetchTokenConf.apikey
    }
    const data: ApiScanResponse<ITransferEvents[]> = await fetch(
      this._fetchTokenConf.url + '/?' + new URLSearchParams(params),
      {cache: "force-cache"}
    ).then(response => response.json())

    if (parseInt(data?.status || '0') === 0 && !data.result) {
      throw new Error(data.message)
    }

    if (parseInt(data?.status || '0') === 1 && data.result.length) {
      const dataToken = data.result[0]

      return {
        symbol: dataToken.tokenSymbol,
        title: dataToken.tokenName,
        address: tokenAddress,
        decimal: Number(dataToken.tokenDecimal),
        img: this.tokensDict[dataToken?.tokenSymbol]?.img || this.defaultTokenImage
      };
    }
    throw new Error("Something was wrong...");
  }

  protected async _estimateFee(txDataForEstimateByAddress: IMapValueByAddress<ITxErc20Data>, gasPriceInWei: bigint) {
    const {txDataByAddress, feeDataByAddress} = await super._estimateFee(txDataForEstimateByAddress, gasPriceInWei)

    /**
     * https://docs.bnbchain.org/bnb-opbnb/faq/gas-and-fees-faqs/#what-is-the-block-gas-limit-on-opbnb
     * l1_data_fee = l1_gas_price * (tx_data_gas + fixed_overhead) * dynamic_overhead
     * fixed_overhead = 2100
     * dynamic_overhead = 1
     * tx_data_gas = count_zero_bytes(tx_data) * 4 + count_non_zero_bytes(tx_data) * 16
     **/

    const l1Provider = setProviderWeb3(process.env.REACT_APP_BSC_WEB3_HTTP_PROVIDER);
    const l1GasPrice = await l1Provider.getGasPriceInWei();
    const fixedOverhead = 2100;

    txDataForEstimateByAddress.forEach((txObject, address) => {
      const txData = txObject.data;
      const zeroBytes = (txData.match(/00/g) || []).length;
      const nonZeroBytes = (txData.length / 2) - zeroBytes;
      const txDataGas = (zeroBytes * 4) + (nonZeroBytes * 16);

      const l1Fee = BigInt(l1GasPrice) * BigInt(txDataGas + fixedOverhead);

      const currentFee = feeDataByAddress.get(address)!
      feeDataByAddress.set(address, currentFee + l1Fee)
    })

    return {txDataByAddress, feeDataByAddress}
  }
}

export {OPBNB_ERC20Facade};