import {IWeb3Facade} from "../IWeb3Facade";
import {
  AddressType,
  IMapValueByAddress,
  ITransactionPriorityEnum
} from "../../types";
import {ETHFacade, InitDataType, ITxEthData} from "../ETH_Network/ETHFacade";
import {ARBTokens} from "../../../../store/arbscan/ARBTokens";
import {GasHelper} from "../../../../helpers";
import {HexStr} from "../../../../store/web3/web3";
import {ARBTestnetTokens} from "../../../../store/arbscan/ARBTestnetTokens";
import {ethers} from "ethers";
import {ArbGasInfo__factory} from "@arbitrum/sdk/dist/lib/abi/factories/ArbGasInfo__factory";

export const ARBTransactionPriorityEnum: ITransactionPriorityEnum = {
  slow: "slow",
  average: "average",
  fast: "fast"
} as const

interface IRawGasPriceItem {
  acceptance: number,
  gasPrice: number | string,
  estimatedFee: number
}

interface IARBGasPrice {
  slow: bigint,
  average: bigint,
  fast: bigint
}

export const ARBInitData: InitDataType = {
  defaultTransactionPriority: ARBTransactionPriorityEnum.average,
  transactionPriorityOptions: {
    [ARBTransactionPriorityEnum.slow]: "Slow",
    [ARBTransactionPriorityEnum.average]: "Average",
    [ARBTransactionPriorityEnum.fast]: "Fast",
  },
  fetchGasPriceConf: {
    apikey: process.env.REACT_APP_LINK_FOR_ARB_GAS_PRICE_API_KEY,
    url: process.env.REACT_APP_LINK_FOR_ARB_GAS_PRICE_API,
    devUrl: process.env.REACT_APP_LINK_FOR_ARB_SCAN_API
  },
  web3HttpProviderLink: process.env.REACT_APP_ARB_WEB3_HTTP_PROVIDER,
  tokensDict: process.env.REACT_APP_ENVIRONMENT === 'dev' ? ARBTestnetTokens : ARBTokens,
  network: 'arb',
  linkForTxScan: process.env.REACT_APP_LINK_FOR_TX_ARB_SCAN,
  addressesChunkSize: 500
}

function adapterGasPrice(rawResult: IRawGasPriceItem[]): IARBGasPrice {
  return {
    slow: BigInt(rawResult[1].gasPrice),
    average: BigInt(rawResult[2].gasPrice),
    fast: BigInt(rawResult[3].gasPrice)
  }
}

class ARBFacade extends ETHFacade implements IWeb3Facade {

  constructor(initData?: InitDataType) {
    super(initData || ARBInitData)
  }

  getTimeout(): number {
    return 500;
  }

  async _fetchGasPriceInWei(transactionPriority: keyof ITransactionPriorityEnum): Promise<bigint> {
    const response = await fetch(`${this._fetchGasPriceConf.url}?apikey=${this._fetchGasPriceConf.apikey}&eip1559=false&reportwei=true`, {
      method: "GET",
      headers: {"Content-Type": "application/json"},
    });
    const result = await response.json() as {
      timestamp: string,
      lastBlock: number,
      avgTime: number,
      avgTx: number,
      avgGas: number,
      avgL1Fee: number,
      speeds: IRawGasPriceItem[]
    };
    /**
     * In test(dev) env use blockscout rpc to get actual price for testnet
     */
    let gasPriceResult: IARBGasPrice = adapterGasPrice(result.speeds)
    if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
      const response = await fetch(`${this._fetchGasPriceConf.devUrl}/eth-rpc`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "id": 0,
          "method": "eth_gasPrice",
          "jsonrpc": "2.0",
          "params": []
        })
      })
      const result = await response.json() as {
        "jsonrpc": string,
        "result": HexStr,
        "id": number
      }
      const slowInWei: bigint = BigInt(result.result)
      switch (transactionPriority) {
        case 'slow':
          return GasHelper.gasPricePlusPercent(slowInWei, 50)
        case 'average':
          return GasHelper.gasPricePlusPercent(slowInWei, 100)
        case 'fast':
          return GasHelper.gasPricePlusPercent(slowInWei, 200)
      }
    }

    return gasPriceResult[transactionPriority as keyof IARBGasPrice] || BigInt(0)
  }

  async __getGasLimit(sender: AddressType, receiver: AddressType): Promise<bigint> {
    return await this._web3Provider.estimateGas({
      from: sender,
      to: receiver,
    })
  }

  protected async _estimateFee(txDataForEstimateByAddress: IMapValueByAddress<ITxEthData>, gasPriceInWei: bigint) {
    const {txDataByAddress, feeDataByAddress} = await super._estimateFee(txDataForEstimateByAddress, gasPriceInWei)
    /**
     * Estimate L1 fee via viem doesn't work for Arbitrum
     * So, just taking L1 fee as 10% of L2 fee
     */
    const arbProvider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_ARB_WEB3_HTTP_PROVIDER);

    try {
      // Step 1: Construct the transaction object
      const tx = txDataByAddress.values().next().value;
      const transaction = {
        to: tx.to,
        value: ethers.utils.parseEther(tx.value.toString()),
      };

      // Step 2: Estimate calldata size
      const txData = ethers.utils.serializeTransaction(transaction);
      const calldataSize = ethers.utils.hexDataLength(txData);

      // Step 3: Connect to ArbGasInfo contract
      const arbGasInfoAddress = "0x000000000000000000000000000000000000006C"; // ArbGasInfo address
      const arbGasInfo = ArbGasInfo__factory.connect(arbGasInfoAddress, arbProvider);

      // Step 4: Get L1 gas and calldata prices
      const gasPrices = await arbGasInfo.getPricesInWei();
      const l1CalldataPricePerByte = gasPrices[1]; // Price per byte of calldata

      // Step 5: Calculate L1 fee
      const l1Fee = l1CalldataPricePerByte.mul(calldataSize);
      txDataByAddress.forEach((item, address) => {
        const currentFee = feeDataByAddress.get(address)!
        feeDataByAddress.set(address, currentFee + BigInt(l1Fee.toString()))
      });
    } catch (error) {
      txDataByAddress.forEach((item, address) => {
        const currentFee = feeDataByAddress.get(address)!
        feeDataByAddress.set(address, GasHelper.gasPricePlusPercent(currentFee, 10))
      });
    }

    return {txDataByAddress, feeDataByAddress}
  }
}

export {ARBFacade}