import {ITokenDict, NetworkCurrencyEnum} from "../../pages/ConsolidationTool/types";
import bscEmpty from '../../assets/images/bsc-empty-token.svg'

const OPBNB_DEFAULT_IMG = bscEmpty as string

const OPBNBTestnetTokens: ITokenDict = {
  [NetworkCurrencyEnum.bsc]: {
    "title": "Binance Coin",
    "img": "https://bscscan.com/assets/bsc/images/svg/logos/token-light.svg",
    "rate": "$405.00",
    "address": undefined,
    "link": "https://bscscan.com/",
    "group": `Base Currency (${NetworkCurrencyEnum.bsc})`,
    "website": "https://www.bnbchain.org/",
    "desc": "",
    "decimal": 18,
    "symbol": NetworkCurrencyEnum.bsc
  },
  HCM: {
    "title": "Hachiman Token",
    "img": bscEmpty,
    "rate": "$1.00",
    "address": "0xF7164EF7d76Eb99C6BCC1059501582bA670dee82",
    "link": "https://opbnb-testnet.bscscan.com/token/0xf7164ef7d76eb99c6bcc1059501582ba670dee82",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 18,
    "symbol": "HCM"
  },
  OMK: {
    "title": "Omoikane Token",
    "img": bscEmpty,
    "rate": "$1.00",
    "address": "0x886E479B3612De2062a7Bc483C0c4A9485798391",
    "link": "https://opbnb-testnet.bscscan.com/token/0x886E479B3612De2062a7Bc483C0c4A9485798391",
    "group": "Testnet Tokens (ERC 20)",
    "website": "",
    "desc": "",
    "decimal": 18,
    "symbol": "OMK"
  },
}

export {OPBNBTestnetTokens, OPBNB_DEFAULT_IMG}