import React from "react";
import {useParams} from "react-router-dom";
import {withFacade} from "./hoc/withFacade";
import {DisperseToolView} from "./Views/DisperseToolView";
import {IMapValueByAddress, NetworkType} from "../ConsolidationTool/types";
import {BASE_DisperseFacade} from "./facades/BASE_DisperseFacade";
import {ETH_DisperseFacade} from "./facades/ETH_DisperseFacade";
import {BSC_DisperseFacade} from "./facades/BSC_DisperseFacade";
import {IWeb3DisperseFacade} from "./facades/IWeb3DisperseFacade";
import {SOL_DisperseFacade} from "./facades/SOL_DisperseFacade";
import {ARB_DisperseFacade} from "./facades/ARB_DisperseFacade";
import {DisperseAvailableNetworks} from "./types";
import {OPT_DisperseFacade} from "./facades/OPT_DisperseFacade";
import {POL_DisperseFacade} from "./facades/POL_DisperseFacade";
import {AVAX_DisperseFacade} from "./facades/AVAX_DisperseFacade";
import {OPBNB_DisperseFacade} from "./facades/OPBNB_DisperseFacade";

export const DISPERSE_FACADES: Record<Extract<NetworkType, DisperseAvailableNetworks>, new() => IWeb3DisperseFacade> = {
  'eth': ETH_DisperseFacade,
  'bsc': BSC_DisperseFacade,
  'opbnb': OPBNB_DisperseFacade,
  'base': BASE_DisperseFacade,
  'arb': ARB_DisperseFacade,
  'opt': OPT_DisperseFacade,
  'pol': POL_DisperseFacade,
  'avax': AVAX_DisperseFacade,
  'sol': SOL_DisperseFacade,
}

function getFacadeByNetwork(network?: NetworkType): IWeb3DisperseFacade {
  const Facade = DISPERSE_FACADES[network]; // type is automatically inferred here
  if (Facade) {
    return new Facade()
  }

  throw new Error(`Network ${network} is not implemented for Disperse tool.`);

}

const FactoryDisperseTool = (props: {
  recipientsAndValuesForChargeInUnit?: IMapValueByAddress<bigint>,
  network?: NetworkType
}) => {
  const {network} = useParams<{ network: string }>()
  const initData = props?.recipientsAndValuesForChargeInUnit ? {recipientsAndValuesForChargeInUnit: props.recipientsAndValuesForChargeInUnit} : undefined

  const facade = getFacadeByNetwork((network || 'eth') as NetworkType)
  const ReadyComponent = withFacade(DisperseToolView, facade, initData)

  return (<ReadyComponent/>);
};

export {FactoryDisperseTool}