import {ITokenDict, NetworkCurrencyEnum} from "../../pages/ConsolidationTool/types";
import bscEmpty from '../../assets/images/bsc-empty-token.svg'

const OPBNB_DEFAULT_IMG = bscEmpty as string

const OPBNBTokens: ITokenDict = {
  [NetworkCurrencyEnum.bsc]: {
    "title": "Binance Coin",
    "img": "https://bscscan.com/assets/bsc/images/svg/logos/token-light.svg",
    "rate": "$405.00",
    "address": undefined,
    "link": "https://bscscan.com/",
    "group": `Base Currency (${NetworkCurrencyEnum.bsc})`,
    "website": "https://www.bnbchain.org/",
    "desc": "",
    "decimal": 18,
    "symbol": NetworkCurrencyEnum.bsc
  },
  'USDT': {
    "title": "Tether USD",
    "img": "https://opbnb.bscscan.com/token/images/tether_32.png",
    "rate": "$1.00",
    "address": "0x9e5aac1ba1a2e6aed6b32689dfcf62a509ca96f3",
    "link": "https://opbnb.bscscan.com/token/0x9e5aac1ba1a2e6aed6b32689dfcf62a509ca96f3",
    "group": "Tokens (BEP 20)",
    "website": "https://www.binance.com/en/busd",
    "desc": "",
    "decimal": 18,
    "symbol": "USDT"
  },
  'WBNB': {
    "title": "Wrapped BNB",
    "img": "https://opbnb.bscscan.com/token/images/wbnbchain2_32.png",
    "rate": "$633.59",
    "address": "0x4200000000000000000000000000000000000006",
    "link": "https://opbnb.bscscan.com/token/0x4200000000000000000000000000000000000006",
    "group": "Tokens (BEP 20)",
    "website": "https://www.bnbchain.org/",
    "desc": "",
    "decimal": 18,
    "symbol": "WBNB"
  },
}

export {OPBNBTokens, OPBNB_DEFAULT_IMG}