import {ITokenDict, NetworkCurrencyEnum} from "../../pages/ConsolidationTool/types";

const SPL_DEFAULT_IMG = 'none' as const

const SPLTokens: ITokenDict = {
  [NetworkCurrencyEnum.sol]: {
    "title": "Solana",
    "img": "https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png",
    "rate": "$171.00",
    "address": undefined,
    "link": "https://solscan.io/",
    "group": `Base Currency (${NetworkCurrencyEnum.sol})`,
    "website": "https://solana.com/",
    "desc": "",
    "decimal": 9,
    "symbol": NetworkCurrencyEnum.sol
  },
  "USDT": {
    "title": "Tether",
    "img": "https://raw.githubusercontent.com/solana-labs/token-list/main/assets/mainnet/Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB/logo.svg",
    "rate": "$1",
    "address": 'Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB',
    "link": "https://solscan.io/token/Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB",
    "group": `Tokens (SPL)`,
    "website": "https://tether.to/",
    "desc": "",
    "decimal": 6,
    "symbol": "USDT"
  },
  "TRUMP": {
    "title": "OFFICIAL TRUMP",
    "img": "https://arweave.net/VQrPjACwnQRmxdKBTqNwPiyo65x7LAT773t8Kd7YBzw",
    "rate": "$17.8",
    "address": '6p6xgHyF7AeE6TZkSmFsko444wqoP15icUSqi2jfGiPN',
    "link": "https://explorer.solana.com/address/6p6xgHyF7AeE6TZkSmFsko444wqoP15icUSqi2jfGiPN",
    "group": `Tokens (SPL)`,
    "website": "",
    "desc": "",
    "decimal": 6,
    "symbol": "TRUMP"
  },
}

export {SPLTokens, SPL_DEFAULT_IMG}