import {IWeb3Facade} from "../IWeb3Facade";
import {
  IMapValueByAddress,
  ITransactionPriorityEnum
} from "../../types";
import {ETHFacade, InitDataType, ITxEthData} from "../ETH_Network/ETHFacade";
import {createPublicClient, http} from "viem";
import {optimism, optimismSepolia} from "viem/chains";
import {publicActionsL2} from "viem/op-stack";
import {GasHelper} from "../../../../helpers";
import {HexStr, toHex} from "../../../../store/web3/web3";
import {OPTTestnetTokens} from "../../../../store/optscan/OPTTestnetTokens";
import {OPTTokens} from "../../../../store/optscan/OPTTokens";

export const OptimismTransactionPriorityEnum: ITransactionPriorityEnum = {
  slow: "slow",
  average: "average",
  fast: "fast"
} as const

interface IRawGasPriceItem {
  acceptance: number,
  gasPrice: number | string,
  estimatedFee: number
}

interface IOptimismGasPrice {
  slow: bigint;
  average: bigint,
  fast: bigint
}

export const OptimismInitData: InitDataType = {
  defaultTransactionPriority: OptimismTransactionPriorityEnum.average,
  transactionPriorityOptions: {
    [OptimismTransactionPriorityEnum.slow]: "Slow",
    [OptimismTransactionPriorityEnum.average]: "Average",
    [OptimismTransactionPriorityEnum.fast]: "Fast",
  },
  fetchGasPriceConf: {
    apikey: process.env.REACT_APP_LINK_FOR_OPT_GAS_PRICE_API_KEY,
    url: process.env.REACT_APP_LINK_FOR_OPT_GAS_PRICE_API,
    devUrl: process.env.REACT_APP_LINK_FOR_OPT_SCAN_API
  },
  web3HttpProviderLink: process.env.REACT_APP_OPT_WEB3_HTTP_PROVIDER,
  tokensDict: process.env.REACT_APP_ENVIRONMENT === 'dev' ? OPTTestnetTokens : OPTTokens,
  network: 'opt',
  linkForTxScan: process.env.REACT_APP_LINK_FOR_TX_OPT_SCAN,
  addressesChunkSize: 500
}

function adapterGasPrice(rawResult: IRawGasPriceItem[]): IOptimismGasPrice {
  return {
    slow: BigInt(rawResult[1].gasPrice),
    average: BigInt(rawResult[2].gasPrice),
    fast: BigInt(rawResult[3].gasPrice)
  }
}

class OPTFacade extends ETHFacade implements IWeb3Facade {
  protected static _feeL1: bigint = BigInt(0)

  constructor(initData?: InitDataType) {
    super(initData || OptimismInitData)
  }

  getTimeout(): number {
    return 500;
  }

  async _fetchGasPriceInWei(transactionPriority: keyof ITransactionPriorityEnum): Promise<bigint> {
    const response = await fetch(`${this._fetchGasPriceConf.url}?apikey=${this._fetchGasPriceConf.apikey}&eip1559=false&reportwei=true`, {
      method: "GET",
      headers: {"Content-Type": "application/json"},
    });
    const result = await response.json() as {
      timestamp: string,
      lastBlock: number,
      avgTime: number,
      avgTx: number,
      avgGas: number,
      avgL1Fee: number,
      speeds: IRawGasPriceItem[]
    };
    /**
     * In test(dev) env use blockscout rpc to get actual price for testnet
     */
    let gasPriceResult: IOptimismGasPrice = adapterGasPrice(result.speeds)
    if (process.env.REACT_APP_ENVIRONMENT === 'dev') {
      const response = await fetch(`${this._fetchGasPriceConf.devUrl}/eth-rpc`, {
        method: "POST",
        headers: {"Content-Type": "application/json"},
        body: JSON.stringify({
          "id": 0,
          "method": "eth_gasPrice",
          "jsonrpc": "2.0",
          "params": []
        })
      })
      const result = await response.json() as {
        "jsonrpc": string,
        "result": HexStr,
        "id": number
      }
      const slowInWei: bigint = BigInt(result.result)
      switch (transactionPriority) {
        case 'slow':
          return GasHelper.gasPricePlusPercent(slowInWei, 50)
        case 'average':
          return GasHelper.gasPricePlusPercent(slowInWei, 100)
        case 'fast':
          return GasHelper.gasPricePlusPercent(slowInWei, 200)
      }
    }

    return gasPriceResult[transactionPriority as keyof IOptimismGasPrice] || BigInt(0)
  }

  protected async _estimateFee(txDataForEstimateByAddress: IMapValueByAddress<ITxEthData>, gasPriceInWei: bigint) {
    const {txDataByAddress, feeDataByAddress} = await super._estimateFee(txDataForEstimateByAddress, gasPriceInWei)

    const txDataByAddressIterator = txDataByAddress.values()
    const {done, value} = txDataByAddressIterator.next()
    if (done) {
      return {txDataByAddress, feeDataByAddress}
    }

    let item: ITxEthData = value
    /**
     * Solution if fee greater than balance
     * estimateL1Fee throws an exception if TX Value is lower than 0
     */
    while (item.value < 0) {
      const {done, value} = txDataByAddressIterator.next()
      if (done) {
        return {txDataByAddress, feeDataByAddress}
      }

      item = value
    }

    /**
     * Solution to estimate if balance too small to send transaction
     */
    try {
      if (OPTFacade._feeL1 === BigInt(0)) {
        const publicClient: any = createPublicClient({
          chain: this._environment === 'dev' ? optimismSepolia : optimism,
          transport: http(process.env.REACT_APP_OPT_WEB3_HTTP_PROVIDER),
        } as any).extend(publicActionsL2())

        OPTFacade._feeL1 = await publicClient.estimateL1Fee({
          account: toHex(item.from),
          to: toHex(item.to),
          value: toHex(item.value),
        })
      }

      const feeL1More = GasHelper.gasPay(OPTFacade._feeL1)
      txDataByAddress.forEach((_, address) => {
        feeDataByAddress.set(address, feeDataByAddress.get(address)! + feeL1More)
        const item = txDataByAddress.get(address)!
        if (item.value - feeL1More < BigInt(0)) {
          txDataByAddress.delete(address)
        } else {
          item.value = item.value - feeL1More
          txDataByAddress.set(address, item)
        }
      })
    } catch (error) {
      txDataForEstimateByAddress.forEach((_, address) => {
        const currentFee = feeDataByAddress.get(address)!
        feeDataByAddress.set(address, GasHelper.gasPricePlusPercent(currentFee, 10))
      })
    }

    return {txDataByAddress, feeDataByAddress}
  }
}

export {OPTFacade}