import {
  ComputeBudgetProgram,
  Connection,
  Keypair,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  Transaction,
} from "@solana/web3.js";
import {decode as decodeBase58, encode as encodeBase58} from "bs58"
import {AddressType, ITokenInfo, PrivateKeyType} from "../../pages/ConsolidationTool/types";
import {Web3Context} from "web3";
import {Buffer} from "buffer";
import {SendOptions} from "@solana/web3.js/src/connection";


export const NUM_DROPS_PER_TX = 10;
export const TX_INTERVAL = 1000;

const SOL_PER_LAMPORT = 1 / LAMPORTS_PER_SOL
export const MICRO_LAMPORTS_PER_LAMPORT = 1_000_000;
const SOL_FLOATING_PRECISION = 9


export type GetTokenAccountsByOwnerParsedResponse = {
  context: {
    apiVersion: string,
    slot: number
  },
  value: [
    {
      "account": {
        "data": {
          "parsed": {
            "info": {
              "isNative": boolean,
              "mint": AddressType,
              "owner": AddressType,
              "state": string,
              "tokenAmount": {
                "amount": string,
                "decimals": number,
                "uiAmount": number,
                "uiAmountString": string
              }
            },
            "type": string
          },
          "program": string,
          "space": number
        },
        "executable": boolean,
        "lamports": number,
        "owner": string,
        "rentEpoch": number,
        "space": number
      },
      "pubkey": AddressType
    }
  ]
}

interface IRPC_Request {
  jsonrpc: "2.0" | "1.0"
  id: string,
  method: string,
  params?: [string] | [object] | {}
}

async function fetchTokenInfoMain(tokenAddress: AddressType, chainId: number = 101): Promise<ITokenInfo> {
  type itemContentType = {
    "address": string,
    "chainId": number,
    "name": string,
    "symbol": string,
    "verified": true,
    "decimals": number,
    "holders": number,
    "logoURI": string,
    "tags": [],
    "extensions": {
      "coingeckoId": string
    }
  }
  type responseType = {
    "content": itemContentType[]
  }

  const params = {
    query: tokenAddress,
    chainId: chainId,
    start: '0',
    limit: '1',
  }
  const data: responseType | any = await fetch(
    `https://token-list-api.solana.cloud/v1/search?` + new URLSearchParams(params),
    {cache: "force-cache"}
  ).then(response => response.json())

  if (data?.content && data?.content.length) {
    const dataToken = data.content[0]
    return {
      symbol: dataToken.symbol,
      title: dataToken.name,
      address: tokenAddress,
      img: dataToken.logoURI,
      decimal: Number(dataToken.decimals),
    } as ITokenInfo;
  } else {
    console.error("=> Token not found in Token List");
    const data: responseType | any = await fetch(`https://solana-gateway.moralis.io/token/mainnet/${tokenAddress}/metadata`,
      {
        cache: "force-cache",
        headers: {
          accept: 'application/json',
          'X-API-Key': process.env.REACT_APP_SOL_SCAN_API_KEY
        }
      }
    ).then(response => response.json())

    if (data?.symbol) {
      return {
        symbol: data.symbol,
        title: data.name,
        address: tokenAddress,
        img: data.logo,
        decimal: Number(data.decimals),
      } as ITokenInfo;
    }

    throw new Error("Something was wrong...");
  }
}

async function fetchTokenInfoDev(tokenAddress: AddressType): Promise<ITokenInfo> {
  const __getAssetRequest = (tokenAddress: AddressType): IRPC_Request => ({
    jsonrpc: "2.0",
    id: `${tokenAddress}`,
    method: "getAsset",
    params: {
      id: tokenAddress,
    },
  })
  type ResultAssetRequest = {
    interface: string
    id: string
    content: {
      metadata: {
        description: string
        name: string
        symbol: string
        token_standard: string
      }
      links: {
        image: string
      }
    }
  }
  const __getTokenSupplyRequest = (tokenAddress: AddressType): IRPC_Request => ({
    jsonrpc: "2.0",
    id: `${tokenAddress}`,
    method: "getTokenSupply",
    params: [tokenAddress],
  })
  type ResultTokenSupplyRequest = {
    context: object
    value: {
      amount: string
      decimals: number
      uiAmount: number
      uiAmountString: string
    }
  }
  try {
    return await fetchTokenInfoMain(tokenAddress, 103)
  } catch (error: Error) {
    // skip to try another way
    console.error('Error when try get token info (1): ' + error.message)
  }

  // possible error even if token is existing
  const responseAsset = await __callRPCMethod<ResultAssetRequest>('https://api.devnet.solana.com', __getAssetRequest(tokenAddress))
  //if it is token always return decimal
  const responseForDecimal = await __callRPCMethod<ResultTokenSupplyRequest>('https://api.devnet.solana.com', __getTokenSupplyRequest(tokenAddress))

  if (!responseAsset.error && responseAsset.result && !responseForDecimal.error && responseForDecimal.result) {
    return {
      symbol: responseAsset.result.content.metadata.symbol,
      title: responseAsset.result.content.metadata.name,
      address: tokenAddress,
      img: responseAsset.result.content.links.image,
      decimal: Number(responseForDecimal.result.value.decimals),
    }
  }
  throw new Error("Error when try get token info (2): " + (responseAsset.error?.message || responseForDecimal.error?.message));
}

type RPC_CallResponse<ResultType> = {
  "jsonrpc": "2.0",
  "result"?: ResultType,
  error?: { code: number, message: string }
  "id": AddressType
}

async function __callRPCMethod<ResultType>(linkHttpProvider: string, body: IRPC_Request): Promise<RPC_CallResponse<ResultType>> {
  const response = await fetch(linkHttpProvider, {
    method: "POST",
    headers: {"Content-Type": "application/json"},
    body: JSON.stringify(body),
  });
  return await response.json();
}


/**
 * not real or exactly limit
 * just for skip some rpc node
 *
 * follow numbers means count of rpc call per http request
 */
export type LimitRpcHttp = 10 | 100 | 1000 | 10000 | 100000

export const devNetProvidersRpcHttp: Array<{
  rpcHttp: string,
  limit: LimitRpcHttp,
  websoket?: string,
}> = [
  {rpcHttp: 'https://devnet.helius-rpc.com/?api-key=4b1ac6d2-c07e-4d1a-823b-a813af0df797', limit: 10},
  {rpcHttp: 'https://devnet.helius-rpc.com/?api-key=17a8d914-f8e0-4ae3-ac51-9da6f7fda713', limit: 10},
  {rpcHttp: 'https://nd-593-186-741.p2pify.com/10ba0786b05daf5058efd4e007e60222', limit: 100},
  {
    rpcHttp: 'https://rpc.ankr.com/solana_devnet/e0d86470574f2e6a2c92028aa7c3adffe3f591aa96111d9b6027c4bea0556415',
    limit: 100
  },
  {rpcHttp: 'https://solana-devnet.g.alchemy.com/v2/5o4gmyqnmqP8g8xSuhmQWi29nzpj4p52', limit: 1000}
]

export const mainNetProvidersRpcHttp: Array<{
  rpcHttp: string,
  limit: LimitRpcHttp,
  websoket?: string,
}> = [
  // {rpcHttp: 'https://mainnet.helius-rpc.com/?api-key=17a8d914-f8e0-4ae3-ac51-9da6f7fda713', limit: 10},
  // {rpcHttp: 'https://mainnet.helius-rpc.com/?api-key=4b1ac6d2-c07e-4d1a-823b-a813af0df797', limit: 10},
  //
  // {rpcHttp: 'https://go.getblock.io/a6471ec93ef24d27b05c559bfbec366a', limit: 100},
  // {rpcHttp: 'https://go.getblock.io/0fc64b70f1854a5498d9f1f22b07aa4a', limit: 100},
  //
  // {rpcHttp: 'https://solana-mainnet.core.chainstack.com/aad8dbfb20d93b79dac0e1e8a9fa5457', limit: 100},
  // {rpcHttp: 'https://solana-mainnet.core.chainstack.com/04ac989a7cfb2e24935523f4c6eb3d12', limit: 100},
  //
  // {rpcHttp: 'https://solana-mainnet.g.alchemy.com/v2/g6eeTNOE4rcXfDHcML9-w9KYm18w9k2j', limit: 1000},
  // {rpcHttp: 'https://solana-mainnet.g.alchemy.com/v2/5o4gmyqnmqP8g8xSuhmQWi29nzpj4p52', limit: 1000},
  // {
  //   rpcHttp: 'https://sol.nownodes.io/747f652c-dff7-4ce6-885c-67a6f479939d',
  //   websoket: 'wss://sol.nownodes.io/wss/747f652c-dff7-4ce6-885c-67a6f479939d',
  //   limit: 100000
  // },
  {
    rpcHttp: 'https://lb.drpc.org/ogrpc?network=solana&dkey=Al7ystMfb09TgkbIUVdjRcKSxGJ95W0R76qdqi5fk9AX',
    websoket: 'wss://lb.drpc.org/ogws?network=solana&dkey=Al7ystMfb09TgkbIUVdjRcKSxGJ95W0R76qdqi5fk9AX',
    limit: 100000
  },
]


export function setProviderWeb3(linkHttpProvider: string, webSoketProvider?: string) {
  let connection: Connection;
  if (webSoketProvider) {
    connection = new Connection(linkHttpProvider, {wsEndpoint: webSoketProvider, commitment: "confirmed"})
  } else {
    connection = new Connection(linkHttpProvider, "confirmed")
  }

  /**
   * @param {float} amount
   * @param {int} length
   *
   * @return {float}
   */
  function __truncate_float(amount: number, length: number): number {
    amount = amount * Math.pow(10, length)
    amount = Math.trunc(amount)
    amount /= Math.pow(10, length)
    return amount
  }

  function lamport_to_sol(lamports: number): number {
    return __truncate_float(lamports * SOL_PER_LAMPORT, SOL_FLOATING_PRECISION)
  }

  function sol_to_lamport(sol: number): number {
    return Math.trunc(sol * LAMPORTS_PER_SOL)
  }

  const __getBalanceRequest = (address: AddressType, prefix = ''): IRPC_Request => ({
    jsonrpc: "2.0",
    id: `${prefix.length ? prefix + '.' : prefix}${address}`,
    method: "getBalance",
    params: [
      address
    ]
  })
  const __sendRawTransactionRequest = (rawTransaction: Buffer | Uint8Array | Array<number>, address: AddressType, options?: SendOptions): IRPC_Request => {

    const encodedTransaction: string = Buffer.from(rawTransaction).toString('base64')
    const config: object = {encoding: 'base64'};
    const skipPreflight = options && options.skipPreflight;
    const preflightCommitment =
      (options && options.preflightCommitment) || "confirmed";

    if (options && options.maxRetries != null) {
      config.maxRetries = options.maxRetries;
    }
    if (options && options.minContextSlot != null) {
      config.minContextSlot = options.minContextSlot;
    }
    if (skipPreflight) {
      config.skipPreflight = skipPreflight;
    }
    if (preflightCommitment) {
      config.preflightCommitment = preflightCommitment;
    }
    return {
      jsonrpc: "2.0",
      id: `${address}`,
      method: "sendTransaction",
      params: [
        encodedTransaction,
        config
      ]
    }
  }
  /**
   * Returns all SPL Token accounts by token owner.
   * @inheritDoc https://www.quicknode.com/docs/solana/getTokenAccountsByOwner
   *
   * @param walletPubKey
   * @param tokenAddress
   *
   * @see GetTokenAccountsByOwnerParsedResponse
   */
  const __getTokenAccountsByOwnerRequest = (walletPubKey: PublicKey, tokenAddress: PublicKey): IRPC_Request => ({
    jsonrpc: "2.0",
    id: `${walletPubKey.toBase58()}`,
    method: "getTokenAccountsByOwner",
    params: [
      walletPubKey.toBase58(),//The Pubkey of account delegate to query encoded as base-58 string
      {
        "mint": tokenAddress.toBase58() //The Pubkey of the specific token Mint to limit accounts to, as base-58 encoded string
      },
      {
        "encoding": "jsonParsed"
      },
    ]
  })


  /**
   * @experimental Only for Helius RPC !!!
   *  docs https://docs.helius.dev/solana-rpc-nodes/alpha-priority-fee-api
   * @param rawTransaction
   * @param address
   */
  const __getPriorityFeeEstimateRequest = (rawTransaction: Buffer | Uint8Array | Array<number>, address: AddressType): IRPC_Request => ({
    jsonrpc: "2.0",
    id: `${address}`,
    method: "getPriorityFeeEstimate",
    params: [
      {
        transaction: encodeBase58(rawTransaction), // Pass the serialized transaction in Base58
        options: {
          // priorityLevel: priorityLevel,
          includeAllPriorityFeeLevels: true
        },
      },
    ],
  })

  const getBalance = Object.assign(connection.getBalance.bind(connection), {
    request: __getBalanceRequest,
    outputFormatter: BigInt
  })
  const sendRawTransaction = Object.assign(connection.sendRawTransaction.bind(connection), {
    request: __sendRawTransactionRequest,
  })
  const getParsedTokenAccountsByOwner = Object.assign(connection.getParsedTokenAccountsByOwner.bind(connection), {
    request: __getTokenAccountsByOwnerRequest,
    outputFormatter: BigInt
  })

  /**
   * Only for Helius RPC, only in production !!!
   * @experimental more details https://docs.helius.dev/solana-rpc-nodes/alpha-priority-fee-api
   * As alternative you may use QuickNode, more details https://marketplace.quicknode.com/add-on/solana-priority-fee
   */
  const getPriorityFeeEstimate = Object.assign(async (rawTransaction: Buffer | Uint8Array | Array<number>, address: AddressType) => {
    const linkHttpProvider = 'https://mainnet.helius-rpc.com/?api-key=17a8d914-f8e0-4ae3-ac51-9da6f7fda713' as const
    type ResultGetPriorityFeeEstimateRequest = {
      "priorityFeeLevels": { // for options {includeAllPriorityFeeLevels: true}
        "min": number,
        "low": number,
        "medium": number,
        "high": number,
        "veryHigh": number,
        "unsafeMax": number
      }
    }
    return await __callRPCMethod<ResultGetPriorityFeeEstimateRequest>(linkHttpProvider, __getPriorityFeeEstimateRequest(rawTransaction, address));

  }, {
    request: __getPriorityFeeEstimateRequest,
  })

  function BatchRequestInit() {
    return new Web3Context(linkHttpProvider).BatchRequest
  }

  return {
    connection,
    fetchTokenInfoDev,
    fetchTokenInfoMain,
    getParsedTokenAccountsByOwner,
    getMinimumBalanceForRentExemption: connection.getMinimumBalanceForRentExemption.bind(connection),
    simulateTransaction: connection.simulateTransaction.bind(connection),
    getLatestBlockhash: connection.getLatestBlockhash.bind(connection),
    PublicKey,
    SystemProgram,
    ComputeBudgetProgram,
    Transaction, Keypair,
    sendRawTransaction,
    getPriorityFeeEstimate,
    getBalance,
    getAccountInfo: connection.getAccountInfo.bind(connection),
    getKeyPair: (privateKey: PrivateKeyType) => {
      return Keypair.fromSecretKey(decodeBase58(privateKey), {skipValidation: false})
    },
    privateKeyToAccount: (privateKey: PrivateKeyType) => {
      const keyPair = Keypair.fromSecretKey(decodeBase58(privateKey), {skipValidation: false})
      return {
        address: keyPair.publicKey.toBase58(),
        /**
         * better to use encodeBase58(keyPair.secretKey)
         * but for optimize perform may use fn param [privateKey]
         */
        privateKey: privateKey,
        // privateKey: encodeBase58(keyPair.secretKey)
      }
    },
    isAddress: (address: AddressType) => {
      const pubKey = new PublicKey(address)
      return PublicKey.isOnCurve(pubKey.toString())
    },
    lamport_to_sol,
    sol_to_lamport,
    BatchTransaction: Transaction,
    BatchRequest: BatchRequestInit()
  }
}

export type Web3SolType = ReturnType<typeof setProviderWeb3>

